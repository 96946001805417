<template>
  <div>
     <div>
      <headerTop></headerTop>
    </div>
    <headerMin>
      <div class="hader-top-min-1">
        <i class="fl"></i>
        <span></span>
        <div class="fl logo htm1">服务声明</div>
      </div>
    </headerMin>
    <div class="wrap aboutus-content">
      <div class="img">
        <!-- <img src="../../assets/image/bg1.jpg" alt=""> -->
      </div>
      <div ref="txt" class="txt"></div>
    </div>
    <publicBottom> </publicBottom> 
  </div>
</template>
<script>
import headerMin from "@/components/public/public_headerMin.vue";
import publicBottom from "@/components/public/public_bottom.vue";
import headerTop from "@/components/public/public_headerTop.vue";

import { getSysconfs } from "@/request/public";

export default {
  components: {
    headerMin,publicBottom,headerTop
  },
  data() {
    return {};
  },
  created() {
    getSysconfs({ token: this.$token }).then(res => {
      if (res.code == 1) {
        this.config = res.data;
        console.log(res.data)
        this.$refs.txt.innerHTML = this.config.fuwutiaokuan
      }
    });
  }
};
</script>
<style lang="less" scoped>
.hader-top-min-1 {
  span {
    float: left;
    height: 38px;
    width: 1px;
    background: #999;
    margin-left: 20px;
    margin-top: 27px;
  }
  .htm1 {
    padding-left: 20px;
    font-size: 38px;
    margin-top: -6px;
    margin-left: 0px;
  }
}
</style>